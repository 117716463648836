import React from 'react';
import './Header.css';


const Header = () => {
    return(
        <div id="HEADER" className="header-container">
            <div className="header-cover">
                <div className="heading-1">
                    Give Hope For Children
                </div>
                <div className="heading-2">
                    Helping Each Other
                </div>
                <div className="heading-2">
                    Can Make World Better
                </div>
                <div className="heading-3">
                    We seek out world changers and difference makers around the  <br/>
                </div>
                <div className="heading-3">
                    Globe, and Equip them to fullfill their unique purpose.
                </div>
            </div>
        </div>
    );
};

export default Header;