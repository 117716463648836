import React from "react";
import {useState} from "react";
import './Navbar2.css';
import gvLogo from '../../global/assets/gv_logo.svg';


const Navbar2 = () => {
    const [toggle, setToggle]= useState(false);
    const [collapse, setCollapse] = useState(false);

    const clickToggle = () => {
        setToggle(toggle?false:true);
        setCollapse(toggle?false:true);
    };

    return (
        <div>
            <nav className="navbar2-component">
                <div className="navbar2-gv-logo"> <img src={gvLogo} alt="Gratitude as Virtue"/> </div>
                <div className="navbar2-gv-title">
                    <div className="navbar2-heading">Gratitude</div>
                    <div className="navbar2-heading">as Virtue</div>
                </div>
                <div className="navbar2-fill-space"></div>
                <ul className={collapse?"navbar2-list list-active":"navbar2-list list-inactive"}>
                    <li className="navbar2-item" onClick={clickToggle}><a href="#HEADER">Home</a></li>
                    <li className="navbar2-item" onClick={clickToggle}><a href="#ABOUT-US">About us</a></li>
                    <li className="navbar2-item" onClick={clickToggle}><a href="#TEAM">Team</a></li>
                    <li className="navbar2-item" onClick={clickToggle}><a href="#CONTACT-US">Contact Us</a></li>
                </ul>
                <div className="navbar-mobile">
                    <i className={toggle ? "fas fa-times fa-3x" : "fas fa-bars fa-3x"} onClick={clickToggle}></i>
                </div>
            </nav>
        </div>
    )
};

export default Navbar2;