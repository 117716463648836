import React from "react";
import './DonateUs.css';
import donateUsGraphic from './assets/donate-us-graphic.svg';
import donateMailIcon from './assets/donate-mail-icon.svg';
import donatePhoneIcon from './assets/donate-phone-icon.svg';
import qrCode from './assets/sk-qr.png';
import gvLogo from '../../global/assets/gv_logo.svg';


const DonateUs = () => {
    return(
        <div id="DONATE-US" className="donate-us-container">
            <div className="donate-us-primary-heading">Donate Us</div>
            <div className="donate-us-primary-text">Alone we can do so little, together, we can do so much.</div>

            <div className="donate-us-body">
                <div className="donate-us-text-section">
                    {/* <div className="donate-us-graphic"><img className="donate-us-graphic-image" src={} alt="donate"></img></div> */}
                    <div className="donate-us-contact">Contact us for more info regarding donations</div>
                    <div className="donate-us-info">
                        <div className="donate-us-email">
                            <div className="donate-us-icon"><img src={donateMailIcon} alt="mail"></img></div>
                            <div className="donate-us-text">gv.ngo1972@gmail.com</div>
                        </div>
                        {/* <div className="donate-us-phone">
                            <div className="donate-us-icon"><img src={donatePhoneIcon} alt="phone"></img></div>
                            <div className="donate-us-text">+91-9xxxxxxxxx</div>
                        </div> */}
                    </div>
                </div>
                <div className="donate-us-qr-section">
                    <div className="donate-us-qr-block"><img className="qr-code" src={qrCode} alt="QR Code"></img></div>
                    <div className="donate-us-qr-text">Scan QR to Donate</div>
                    <div className="donate-us-qr-text">Or use UPI Id: dr.sakshi0720@oksbi</div>

                </div>
            </div>
        </div>
    );
}

export default DonateUs;