import React from 'react';
import {Header, AboutUs, DonateUs, Team, ContactUs, Footer} from '../containers/index';
import {Navbar1, Navbar2} from '../components/index';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import './App.css';

const firebaseConfig = {
  apiKey: "AIzaSyDVCiFeXuId2JjPmitQPufSJVhka5KdoOk",
  authDomain: "gv-web-c9527.firebaseapp.com",
  projectId: "gv-web-c9527",
  storageBucket: "gv-web-c9527.appspot.com",
  messagingSenderId: "785030833633",
  appId: "1:785030833633:web:6a4f77124ef104d7865ea0",
  measurementId: "G-Y24RCSVRKB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <React.Fragment>
        <div className="App">
        {/* <Navbar1/> */}
        <Navbar2/>
        <Header/>
        <AboutUs/>
        <DonateUs/>
        <Team/>
        <ContactUs/>
        <Footer/>
      </div>
    </React.Fragment>
  );
}
export default App;
