import './AboutUs.css';
import ourStoryIcon from './assets/story.png';
import ourMissionIcon from './assets/mission.png';
import ourVisionIcon from './assets/vision.png';



const AboutUs = () => {
    return(
        <div id="ABOUT-US" className="about-us-container">
            <div className="about-us-heading">About Us</div>
            <div className="about-us-sections">
                <div className="about-us-section our-story">
                    <div className="about-us-section-header">
                        <div className="about-us-section-logo">
                            <img className="about-us-section-icon" src={ourStoryIcon} alt="our story icon"></img>
                        </div>
                        <div className="about-us-section-title">Our Story</div>
                    </div>
                    <div className="about-us-section-description">
                    Children are the future of mankind. It is a fact that children brought up in a healthy and happy environment along with good education, grow up to be better human beings and consequently better citizens, thereby contributing more to the society in general. They are the ones who are rendered homeless with no one to love or be loved, with no one to take care of their basic needs like food and shelter, let alone provide them education and care.
                    </div>
                </div>
                <div className="about-us-section our-mission">
                <div className="about-us-section-header">
                        <div className="about-us-section-logo">
                            <img className="about-us-section-icon" src={ourMissionIcon} alt="our story icon"></img>
                        </div>
                        <div className="about-us-section-title">Our Mission</div>
                    </div>
                    <div className="about-us-section-description">
                        <ul>
                            <li>GV NGO aims to provide basic education to the underprivileged children as far as we can reach.</li>
                            <li>We also endeavour to plant saplings among others in order to spread awareness about climate change and environment degradation.</li>
                            <li>We aim to bring in our folds as many people who really on ground wants help others.</li>
                            <li> We will try to coneect people who want to donate in the welfare of the needy</li>
                            <li>Will aim to provide children with books that are not useful to others but can prove to build an opportunity for them.</li>
                        </ul>
                    </div>
                </div>
                <div className="about-us-section our-vision">
                    <div className="about-us-section-header">
                            <div className="about-us-section-logo">
                                <img className="about-us-section-icon" src={ourVisionIcon} alt="our story icon"></img>
                            </div>
                            <div className="about-us-section-title">Our Vision</div>
                        </div>
                        <div className="about-us-section-description">
                            <ul>
                                <li>At the utmost level of achievement, We want to build our own orphanage and old age home.</li>
                                <li>We aspir to provide the people who are neglected by their families with descent shelter and basic necessities of life that they deserve.</li>
                                <li>Once we grow of good financial standing , get recognized and start receiving donations we will provide children with quality education and also help create job opportunities for them</li>
                            </ul>
                            <b>AT LAST WE STRUGGLE TO PROVIDE THE BASIC RIGHTS AND COMMODITIES THAT EACH AND EVERY PERSON DESERVES</b>
                        </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;