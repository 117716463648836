import React from "react";
import Sakshi from "./assets/sakshi.jpeg";
import Dilip from "./assets/dilip.jpeg";
import Vivek from "./assets/vivek-2.jpeg"
import Rajni from "./assets/rajni-1.jpeg"
import './Team.css';


const Team = () => {
    return(
        <div id="TEAM" className="team-container">
            <div className="team-heading">Who we are</div>
            <div className="team-body">
                <div className="team-primary">
                    <div className="team-profile-primary">
                        <div className="primary-profile-image">
                            <img className="profile-image" src={Sakshi} alt="sakshi"/>
                        </div>
                        <div className="primary-profile-name">
                            Sakshi
                        </div>
                        <div className="primary-profile-title">
                            Founder
                        </div>
                    </div>
                    <div className="team-profile-primary">
                        <div className="primary-profile-image">
                                <img className="profile-image" src={Vivek} alt="sakshi"/>
                            </div>
                            <div className="primary-profile-name">
                                Dr. Vivek Pachauri
                            </div>
                            <div className="primary-profile-title">
                                Founder
                        </div>
                    </div>
                    <div className="team-profile-primary">
                        <div className="primary-profile-image">
                            <img className="profile-image" src={Dilip} alt="sakshi"/>
                        </div>
                        <div className="primary-profile-name">
                            Dilip
                        </div>
                        <div className="primary-profile-title">
                            Co Founder
                        </div>
                    </div>
                    <div className="team-profile-primary">
                        <div className="primary-profile-image">
                                <img className="profile-image" src={Rajni} alt="sakshi"/>
                            </div>
                            <div className="primary-profile-name">
                                Rajni Sharma
                            </div>
                            <div className="primary-profile-title">
                                Member
                        </div>
                    </div>
                </div>
                <div className="team-secondary">
                    
                </div>
            </div>
            <div className="team-volunteer">
                <div className="team-volunteer-text">
                    <div className="team-volunteer-heading">Be a Part of our Team</div>
                    <div className="team-volunteer-description">Volunteers do not necessarily have the time, they just have the heart</div>
                </div>
                <a className="team-volunteer-btn" href="https://docs.google.com/forms/d/e/1FAIpQLSfB2vr0V8TTZpqW7d0CwvnQwGfQ2gPqna9aJUAs7xQqtRJVrw/viewform" target="_blank">
                    <div >
                        <div className="team-volunteer-register">Volunteer</div>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default Team;