import React from "react";
import './Footer.css';
import gvLogo from '../../global/assets/gv_logo.svg';
import edfoalLogo from './assets/edfoal_logo.svg';
import instagramLogo from './assets/instagram-logo.svg';
import facebookLogo from './assets/facebook-logo.svg';
import linkedInLogo from './assets/linkedin-logo.svg';
import twitterLogo from './assets/twitter-logo.svg';


const Footer = () => {
    return (
        <div id="FOOTER" className="footer-container">
            <div className="footer-top">
                <div className="footer-horizontal-dash"/>
            </div>
            <div className="footer-body">
                <div className="footer-section footer-section-1">
                    <div className="gv-logo"><a href="#HEADER"><img src={gvLogo} alt="GV logo"/></a></div>
                    <div className="footer-gv-name">Gratitude</div>
                    <div className="footer-gv-name">as Virtue</div>
                    <div className="footer-gv-address">Regal Heights Apartment</div>
                    <div className="footer-gv-address">Sector 73, Sarffabad</div>
                    <div className="footer-gv-address">Noida</div>
                </div>
                <div className="footer-vertical-dash"/>
                <div className="footer-section footer-section-2">
                    <div className="footer-section-heading">Quick Links</div>
                    <ul className="footer-list">
                        <li><div className="footer-quick-link"><a href="#HEADER">Home</a></div></li>
                        <li><div className="footer-quick-link"><a href="#ABOUT-US">About us</a></div></li>
                        <li><div className="footer-quick-link"><a href="#TEAM">Teams</a></div></li>
                        <li><div className="footer-quick-link"><a href="#DONATE-US">Donation</a></div></li>
                        <li><div className="footer-quick-link"><a href="#TEAM">Volunteer</a></div></li>
                    </ul>                    
                </div>
                {/* <div className="footer-vertical-dash"/> */}
                <div className="footer-section footer-section-3">
                    <div className="footer-section-heading">Follow us</div>
                    <div className="footer-mobile-social-handle">
                        <a href="https://www.instagram.com/gv.ngo/?igshid=ZDdkNTZiNTM%3D" target="_blank" className="footer-social-handle">
                            <div className="social-logo"><img src={instagramLogo} alt="gv instagram handle"/></div>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100050166139864" target="_blank" className="footer-social-handle">
                            <div className="social-logo"><img src={facebookLogo} alt="gv facebook handle"/></div>
                        </a>
                        <a href="https://www.linkedin.com/in/sakshi-sharma-61a1a41b4" target="_blank" className="footer-social-handle">
                            <div className="social-logo"><img src={linkedInLogo} alt="gv linkedIn handle"/></div>
                        </a>
                        <div className="footer-social-handle">
                            <div className="social-logo"><img src={twitterLogo} alt="gv twitter handle"/></div>
                        </div>
                    </div>
                    <ul className="footer-follow-us">
                        <a href="https://www.instagram.com/gv.ngo/?igshid=ZDdkNTZiNTM%3D" target="_blank" className="footer-social-handle">
                            <div className="social-logo"><img src={instagramLogo} alt="gv instagram handle"/></div>
                            <div className="social-title"> Instagram</div>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100050166139864" target="_blank" className="footer-social-handle">
                            <div className="social-logo"><img src={facebookLogo} alt="gv facebook handle"/></div>
                            <div className="social-title"> Facebook</div>
                        </a>
                        <a href="https://www.linkedin.com/in/sakshi-sharma-61a1a41b4" target="_blank" className="footer-social-handle">
                            <div className="social-logo"><img src={linkedInLogo} alt="gv linkedIn handle"/></div>
                            <div className="social-title"> Linkedin</div>
                        </a>
                        <a href="" target="_blank" className="footer-social-handle">
                            <div className="social-logo"><img src={twitterLogo} alt="gv twitter handle"/></div>
                            <div className="social-title"> Twitter</div>
                        </a>
                    </ul>
                </div>
                {/* <div className="footer-vertical-dash"/> */}
                <div className="footer-section footer-section-4">
                    <div className="footer-section-heading">Technology Partners</div>
                    <div className="edfoal-logo"><a target="_blank" href="https://edfoal.com/"><img src={edfoalLogo} alt="EdFoal logo"/></a></div>
                    <div className="footer-edfoal-name">Edfoal</div>
                    <div className=" footer-quick-link footer-edfoal-email"><u>info@edfoal.com</u></div>
                </div>
            </div>
            <div className="footer-bottom">
                Copyright ©2022 All rights reserved.
            </div>
        </div>
    );
};

export default Footer;
