import React, {useState} from "react";
import './ContactUs.css';
import locationDemo from './assets/location.png';
import emailjs from '@emailjs/browser';


const ContactUs = () => {

    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
    })

    const handleFirstNameChange = (event) => {
        setValues({...values, firstName: event.target.value});
    }

    const handleLastNameChange = (event) => {
        setValues({...values, lastName: event.target.value});
    }

    const handleEmailChange = (event) => {
        setValues({...values, email: event.target.value});
    }

    const handlePhoneChange = (event) => {
        setValues({...values, phone: event.target.value});
    }

    const handleMessageChange = (event) => {
        setValues({...values, message: event.target.value});
    }

    const sendMessage = () => {
        emailjs.send("service_y8wcn0p", "template_f52rybi", {
            from_name: values.firstName+" "+values.lastName,
            message: values.message,
            email_id: values.email,
            phone_no: values.phone,
        },"A6nKc2aICqDASJz4o").catch((err) => {
            console.log(err);
        })
    }

    return(
        <div className="contact-us-container">
            <div id="CONTACT-US" className="contact-us-heading">Contact Us</div>
            <div className="contact-us-body">
                <div className="contact-us-message">
                    <div className="contact-us-heading-1">Get In Touch -</div>
                    <div className="contact-us-heading-2">Send us a message</div>
                    <form>

                    <div className="contact-us-form-grid">
                        <div className="contact-us-form-grid-item-1 cs-grid-item">
                            <div className="cs-grid-item-text">First Name</div>
                            <div className="cs-grid-item-input"><input onChange={handleFirstNameChange} value={values.firstName} className="cs-input fn" type="text" placeholder="First Name"></input></div>
                        </div>
                        <div className="contact-us-form-grid-item-2 cs-grid-item">
                            <div className="cs-grid-item-text">Last Name</div>
                            <div className="cs-grid-item-input"><input onChange={handleLastNameChange} value={values.lastName} className="cs-input fn" type="text" placeholder="Last Name"></input></div>
                        </div>
                        <div className="contact-us-form-grid-item-3 cs-grid-item">
                            <div className="cs-grid-item-text">Email</div>
                            <div className="cs-grid-item-input"><input onChange={handleEmailChange} value={values.email} className="cs-input fn" type="text" placeholder="Email"></input></div>
                        </div>
                        <div className="contact-us-form-grid-item-4 cs-grid-item">
                            <div className="cs-grid-item-text">Phone</div>
                            <div className="cs-grid-item-input"><input onChange={handlePhoneChange} value={values.phone} className="cs-input fn" type="text" placeholder="Phone Number"></input></div>
                        </div>
                        <div className="contact-us-form-grid-item-5 cs-grid-item">
                            <div className="cs-grid-item-text">Message</div>
                            <div className="cs-grid-item-input"><textarea onChange={handleMessageChange} value={values.message} className="cs-input msg" type="text" placeholder=""></textarea></div>
                        </div>
                    </div>
                    <div className="contact-us-send-message" onClick={sendMessage}>
                        <div className="cs-send-btn-label">Send Message</div>
                    </div>
                    </form>

                </div>
                <div className="contact-us-location">
                    <a className="contact-us-location-block" href="https://www.google.com/maps?q=28.5993528,77.3747953&z=17&hl=en" target="_blank">
                        <img className="location" src={locationDemo} alt="location"></img>
                    </a>
                </div>
            </div>

        </div>
    );
}

export default ContactUs;